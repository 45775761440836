<template lang="pug">
#detail 
    .detail-box
        van-swipe(@change="onChange", :autoplay="2000")
            van-swipe-item(v-for="item in detail.car_images_url") 
                .banner(:style="{ backgroundImage: 'url(' + item + ')' }")
            template(#indicator)
                .custom-indicator {{ current + 1 }}/{{ detail.car_images_count }}
        //- .banner(
        //-     :style="{ backgroundImage: 'url(' + detail.thum_image_url + ')' }"
        //- )
            //- img(:src="detail.thum_image_url")
        .detail-li
            .namebox 
                .test-img(v-if="detail.is_check_car == 1")
                    img(src="../../assets/mine/test.png")
                h2.name {{ detail.title }}
                p.xinup {{ detail.title_tag }}
            .numbox
                //-.numbox-l
                    //-span.num-1 指导价：¥
                    //-span.num-2 {{ detail.guide_price }}
                //- .numbox-r
                //-     span.num-1 指导价：{{ detail.guide_price }}
        .car-box(v-if="detail.car_group == 0")
            h2.title 车辆详情
            .content
                p.title-ap(v-html="detail.content") {{ detail.content }}
        .car-box(v-if="detail.car_group == 1")
            h2.title 车辆详情
            form.form-box
                .form-box-item.xq-left
                    p.xq-name 所在地
                    p.xq-content {{ detail.car_location }}
                .form-box-item.xq-left
                    p.xq-name 能源类型
                    p.xq-content {{ detail.energy_type_text }}
                .form-box-item.xq-left
                    p.xq-name 品牌
                    p.xq-content {{ detail.brand }}
                .form-box-item.xq-left
                    p.xq-name 车身颜色
                    p.xq-content {{ detail.body_color_id_text }}
                .form-box-item.xq-left
                    p.xq-name 排放标准
                    p.xq-content {{ detail.emission_standard_text }}
                .form-box-item.xq-left
                    p.xq-name 车系
                    p.xq-content {{ detail.serial }}
                .form-box-item.xq-left
                    p.xq-name 车辆使用性质
                    p.xq-content {{ detail.purpose_text }}
                .form-box-item.xq-left
                    p.xq-name 发动机排量
                    p.xq-content {{ detail.emission }}ml
                .form-box-item.xq-left
                    p.xq-name 车型
                    p.xq-content {{ detail.license_model }}
                .form-box-item.xq-left
                    p.xq-name 车辆概况
                    p.xq-content {{ detail.remark }}
                .form-box-item.xq-left
                    p.xq-name 出厂日期
                    p.xq-content {{ detail.factory_date }}
                .form-box-item.xq-left
                    p.xq-name 行驶里程
                    p.xq-content {{ detail.mileage }}万公里
                .form-box-item.xq-left
                    p.xq-name 变速箱形式
                    p.xq-content {{ detail.gear_box_text }}
        .car-box
            h2.title 车况图片
            p.imgbox
                img(:src="item", v-for="item in detail.car_images_url") 
        .car-box
            h2.title 流程介绍
            p.imgbox2 
                img(:src="process_url")
        .car-box
            h2.title 用户须知
            .content
                p(v-html="user_notice") {{ user_notice }}
        .car-foo
            a.call-box(:href="'tel:' + advisory")
                img.phone-img(src="../../assets/mine/phone.png")
                span.phone-txt 电话咨询
            //-.car-foo-l
                //-.car-all(@click="allbuy()") 全款买车
            .car-foo-r
                .car-fen(@click="fenqibuy()") 分期买车
    //全款
    .buy-box(v-if="show")
        .close(@click="close()")
        .buy-i
            .imgbox(
                :style="{ backgroundImage: 'url(' + detail.thum_image_url + ')' }"
            )
            .buy-ibox 
                p.ibox-p {{ detail.title }}
                p.ibox-s
                    strong.num {{ detail.guide_price }}
                    //span.wan 万起
        form.form-box
            .form-box-item
                label 姓名
                .form-box-in
                    input(
                        type="text",
                        :placeholder="placeholder.contact",
                        max-legth="10",
                        v-model="form.contact"
                    )
            .form-box-item
                label 手机号
                .form-box-in
                    input(
                        type="text",
                        :placeholder="placeholder.contact_phone",
                        max-legth="11",
                        v-model="form.contact_phone"
                    )
            .form-box-item(@click="mendian()")
                label 提车门店
                .form-box-in
                    input.righticon(
                        type="text",
                        :placeholder="placeholder.title",
                        max-legth="11",
                        v-model="store_title"
                    )
        .submit(@click="submitForm", :class="selectId ? 'blue' : ''") 提交
    //门店
    .mendian-box(v-if="show1")
        h2.title 选择提车门店
        .mendian-list
            .mendian-4s(@click="check_store(item)", v-for="item in store_list")
                h3.ss(:class="item.id == selectId ? 'active' : ''") {{ item.title }}
                p.address {{ item.address }}
        .submit(@click="check_show1", :class="selectId ? 'blue' : ''") 确认
    .shade(v-if="show2")
</template>

<style src="./index.styl" lang="stylus"></style>
<style src="../../stylus/reset.styl" lang="stylus"></style>

<script>
import { Radio, Dialog } from "vant";

import { httpDetail, orderSave } from "../../config/request";
import ShareImpl from "@/weixinApi.js";

export default {
    data() {
        return {
            show: false,
            show1: false,
            show2: false,
            active: 0,
            detail: [],
            process_url: "",
            user_notice: "",
            advisory: "",
            store_list: [],
            selectId: "", // 选中门店id
            form: {
                contact: "",
                contact_phone: "",
                store_id: "",
                guide_price: "",
            },

            placeholder: {
                contact: "请输入姓名",
                contact_phone: "请输入11位手机号",
                title: "请选择门店",
            },
            new_car_id: "",
            guide_price: "",
            store_title: "",
            current: 0,
        };
    },
    components: {},

    async created() {
        const id = this.$route.query && this.$route.query.id;
        this.new_car_id = id;
        this.getDatail(id);
    },

    methods: {
        onChange(index) {
            this.current = index;
        },
        allbuy() {
            this.show = true;
            this.show2 = true;
        },
        mendian() {
            this.show1 = true;
            this.show = false;
            this.show2 = true;
        },
        fenqibuy() {
            this.$router.push({ path: "/fenqi?id=" + this.new_car_id });
        },
        close() {
            this.show = false;
            this.show2 = false;
        },
        //获取数据
        getDatail(val) {
            httpDetail({ id: val }).then((res) => {
                //this.list = res.data;
                if (res.code === 20000) {
                    this.detail = res.data.detail;
                    this.process_url = res.data.process_url;
                    this.user_notice = res.data.user_notice;
                    this.advisory = res.data.advisory;
                    this.store_list = res.data.store_list;
                    this.guide_price = res.data.detail.guide_price;
                    console.log(res, "222");
                    // let hashBefore = location.href.split("#")[0],
                    // path = location.href.split("#")[1];
                    // let shareUrl = window.location.href;
                    // let shareUrl = window.location.href;
                    // if(shareUrl.indexOf('from') != -1){
                    //   shareUrl = window.location.href.split("?")[0];
                    // }
                    // window.console.log(url + 'favicon.png');
                    console.log(res, 11111);
                    console.log(val, "valvalval");
                    let img = window.location.href.split("#")[0] + "d.jpg";
                    let shareTitle = "链上汽车";
                    let shareDesc = res.data.share ? res.data.share.share_title : "";
                    console.log(shareDesc, "分享title");
                    let shareImg = res.data.share ? res.data.share.share_image : img;
                    // let shareImg = img;
                    console.log(shareImg, "图片111");
                    console.log(img, "本地111");

                    ShareImpl({
                        shareTitle,
                        shareDesc,
                        shareUrl: location.origin + location.pathname + "?share=true/" + location.hash,
                        shareImg
                    });
                }
            });
        },

        check_store(val) {
            this.form.store_id = val.id;
            this.store_title = val.title;

            this.selectId = val.id;
        },

        check_show1() {
            this.show = true;
            this.show1 = false;
        },

        submitForm() {
            if (this.form.contact == "") {
                this.toast({ message: this.placeholder.contact });
                return false;
            }
            if (this.form.contact_phone == "") {
                this.toast({ message: this.placeholder.contact_phone });
                return false;
            }
            if (!/^1[3-9]\d{9}$/gi.test(this.form.contact_phone)) {
                this.toast({ message: "手机号有误，请重填" });
                return false;
            }
            if (this.form.store_id == "") {
                this.toast({ message: this.placeholder.title });
                return false;
            }
            let data = {
                new_car_id: this.new_car_id,
                buy_type: 0,
                contact: this.form.contact,
                contact_phone: this.form.contact_phone,
                store_id: this.form.store_id,
                guide_price: this.guide_price,
            };
            orderSave(data).then((res) => {
                console.log(res, "res");
                if (res.code === 20000) {
                    this.show = false;
                    this.show2 = false;
                    this.toast({ message: res.message });
                    setTimeout(function () {
                        //跳转到订单页
                        window.location.href =
                            res.data.data + "/mobile/index/index#/order";
                        // " http://esc.web.xmchuangyi.com/mobile/index/index#/order";
                        //" http://mlp.milianauto.com/mobile/index/index#/order";
                    }, 500);
                }
            });
        },
    },
};
</script>
 